import * as data from './data';
import { ResponseMessageTypes, } from './pay_theory_types';
import { handleError } from './message';
// Message Types that would come back from the iframe for async messages
export const CONFIRMATION_STEP = 'pt-static:confirm';
export const CASH_BARCODE_STEP = 'pt-static:cash-complete';
export const COMPLETE_STEP = 'pt-static:complete';
export const ERROR_STEP = 'pt-static:error';
export const FIELDS_READY_STEP = 'pt-static:fields-ready';
export const parseInputParams = (inputParams) => {
    var _a, _b, _c, _d, _e, _f;
    const { payorId, invoiceId, recurringId, metadata = {} } = inputParams;
    const inputCopy = JSON.parse(JSON.stringify(inputParams));
    inputCopy.payTheoryData = {
        account_code: (_a = inputParams.accountCode) !== null && _a !== void 0 ? _a : metadata['pay-theory-account-code'],
        billing_info: inputParams.billingInfo,
        fee: inputParams.fee,
        healthExpenseType: inputCopy.healthExpenseType,
        invoice_id: invoiceId,
        level3DataSummary: inputCopy.level3DataSummary,
        oneTimeUseToken: (_b = inputCopy.oneTimeUseToken) !== null && _b !== void 0 ? _b : false,
        payment_parameters: (_c = inputParams.paymentParameters) !== null && _c !== void 0 ? _c : metadata['payment-parameters-name'],
        payor_id: payorId,
        receipt_description: (_d = inputParams.receiptDescription) !== null && _d !== void 0 ? _d : metadata['pay-theory-receipt-description'],
        recurring_id: recurringId,
        reference: (_e = inputParams.reference) !== null && _e !== void 0 ? _e : metadata['pay-theory-reference'],
        send_receipt: (_f = inputParams.sendReceipt) !== null && _f !== void 0 ? _f : !!metadata['pay-theory-receipt'],
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    inputCopy.metadata = metadata;
    return inputCopy;
};
export const parseConfirmationMessage = (message) => {
    const fee = message.body.fee_mode === data.SERVICE_FEE ? message.body.fee : 0;
    return {
        type: ResponseMessageTypes.CONFIRMATION,
        body: {
            first_six: message.body.first_six,
            last_four: message.body.last_four,
            brand: message.body.brand,
            receipt_number: message.body.idempotency,
            amount: message.body.amount,
            service_fee: fee,
        },
    };
};
export const parseSuccessfulTransactionMessage = (message) => {
    return {
        type: ResponseMessageTypes.SUCCESS,
        body: {
            receipt_number: message.body.receipt_number,
            last_four: message.body.last_four,
            brand: message.body.brand,
            created_at: message.body.created_at,
            amount: message.body.amount,
            service_fee: message.body.service_fee,
            state: message.body.state,
            // Keeping tags in the response for backwards compatibility
            tags: message.body.metadata,
            metadata: message.body.metadata,
            payor_id: message.body.payor_id,
            payment_method_id: message.body.payment_method_id,
        },
    };
};
export const parseFailedTransactionMessage = (message) => {
    return {
        type: ResponseMessageTypes.FAILED,
        body: {
            receipt_number: message.body.receipt_number,
            last_four: message.body.last_four,
            brand: message.body.brand,
            state: message.body.state,
            type: message.body.type,
            payor_id: message.body.payor_id,
            reason: {
                failure_code: message.body.status.reason.error_code,
                failure_text: message.body.status.reason.error_text,
            },
        },
    };
};
export const parseResponse = (message) => {
    switch (message.type) {
        case CONFIRMATION_STEP:
            return parseConfirmationMessage(message);
        case COMPLETE_STEP:
            if (message.paymentType === 'tokenize') {
                return {
                    type: ResponseMessageTypes.TOKENIZED,
                    body: message.body,
                };
            }
            else {
                if (message.body.state === 'FAILURE') {
                    return parseFailedTransactionMessage(message);
                }
                else {
                    return parseSuccessfulTransactionMessage(message);
                }
            }
        case CASH_BARCODE_STEP:
            return {
                type: ResponseMessageTypes.CASH,
                body: message.body,
            };
        case ERROR_STEP:
            return handleError(message.error);
        default:
            return message;
    }
};
export const localizeCashBarcodeUrl = (response) => new Promise(resolve => {
    {
        const options = {
            timeout: 5000,
            maximumAge: 0,
        };
        const success = pos => {
            const crd = pos.coords;
            response.body.mapUrl = `https://map.payithere.com/biller/4b8033458847fec15b9c840c5b574584/?lat=${crd.latitude}&lng=${crd.longitude}`;
            resolve(response);
        };
        const error = () => {
            resolve(response);
        };
        navigator.geolocation.getCurrentPosition(success, error, options);
    }
});
