/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-function */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import PayTheoryHostedField from '../pay-theory-hosted-field';
import common from '../../common';
import { achFieldTypes, BANK_IFRAME, CARD_IFRAME, cardFieldTypes, CASH_IFRAME, cashFieldTypes, defaultFeeMode, eftFieldTypes, transactingWebComponentMap, } from '../../common/data';
import { handleTypedError, postMessageToHostedField, sendAsyncPostMessage, } from '../../common/message';
import { ERROR_STEP, } from '../../common/format';
import { ErrorType, ResponseMessageTypes, } from '../../common/pay_theory_types';
class PayTheoryHostedFieldTransactional extends PayTheoryHostedField {
    constructor(props) {
        super();
        // Used to track if the transact or tokenize function has been called, and we are awaiting a response
        this._initialized = false;
        this._completed = false;
        // Used to track if the element was the one that was used when transact was called
        this._isTransactingElement = false;
        // Used to track if the element is ready to communicate with the transacting iframe
        this._isReady = false;
        // Used to track if the socket is connected
        this._isConnected = false;
        // Used to track what elements are being used for the transaction
        this._processedElements = [];
        this._isValid = false;
        this.transact = this.transact.bind(this);
        this.resetToken = this.resetToken.bind(this);
        this.capture = this.capture.bind(this);
        this.cancel = this.cancel.bind(this);
        this.tokenize = this.tokenize.bind(this);
        this.sendValidMessage = this.sendValidMessage.bind(this);
        this.sendStateMessage = this.sendStateMessage.bind(this);
        this.sendValidMessage = this.sendValidMessage.bind(this);
        this.sendPtToken = this.sendPtToken.bind(this);
        this.handleFeeMessage = this.handleFeeMessage.bind(this);
        this.handleFeeCalcReconnect = this.handleFeeCalcReconnect.bind(this);
        this._transactingIFrameId = props.transactingIFrameId;
        this._stateGroup = props.stateGroup;
        this._transactingType = props.transactingType;
    }
    sendTokenAsync(type) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            try {
                const ptToken = yield common.fetchPtToken((_a = this._apiKey) !== null && _a !== void 0 ? _a : '', this._session);
                if (ptToken) {
                    this._challengeOptions = ptToken.challengeOptions;
                    const transactingIFrame = document.getElementById(this._transactingIFrameId);
                    if (transactingIFrame) {
                        const message = {
                            type: type,
                            data: {
                                token: ptToken['pt-token'],
                                origin: ptToken.origin,
                                fields: this._processedElements,
                            },
                            async: true,
                        };
                        const response = yield sendAsyncPostMessage(message, transactingIFrame);
                        if (response.type === ERROR_STEP) {
                            return handleTypedError(ErrorType.NO_TOKEN, 'Unable validate connection token');
                        }
                        // Mark it as ready if it is the transacting element
                        if (!this._isReady) {
                            this._isReady = true;
                            this.sendReadyMessage();
                        }
                        this._isConnected = true;
                        return {
                            type: 'READY',
                            element: response.element,
                        };
                    }
                    else {
                        return handleTypedError(ErrorType.NO_TOKEN, 'Unable to find transacting iframe');
                    }
                }
                else {
                    return handleTypedError(ErrorType.NO_TOKEN, 'Unable to fetch pt-token');
                }
            }
            catch (e) {
                return handleTypedError(ErrorType.NO_TOKEN, 'Unable to fetch pt-token');
            }
        });
    }
    resetToken() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.sendTokenAsync(`pt-static:reset_host`);
        });
    }
    sendPtToken() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.sendTokenAsync(`pt-static:connection_token`);
        });
    }
    handleFeeMessage(message) {
        if (this._fieldTypes.includes(message.field)) {
            this._fee = message.body.fee;
            this.sendStateMessage();
        }
    }
    handleFeeCalcReconnect(message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this._fieldTypes.includes(message.field)) {
                const result = yield this.resetToken();
                if (result.type === ResponseMessageTypes.READY) {
                    postMessageToHostedField(this._transactingIFrameId, {
                        type: 'pt-static:update-amount',
                        amount: this._amount,
                    });
                }
            }
        });
    }
    connectedCallback() {
        // Set up a listener for the hosted field to message saying it is ready for the pt-token to be sent
        this._removeHostTokenListener = common.handleHostedFieldMessage((event) => {
            return (event.type === 'pt-static:pt_token_ready' &&
                this._transactingIFrameId.includes(event.element));
        }, () => this.sendPtToken());
        this._removeFeeListener = common.handleHostedFieldMessage((event) => event.type === 'pt-static:calculated_fee', (message) => {
            this.handleFeeMessage(message);
        });
        this._removeFeeCalcReconnect = common.handleHostedFieldMessage((event) => event.type === 'pt-static:fee_calc_reconnect', (message) => this.handleFeeCalcReconnect(message));
        super.connectedCallback();
    }
    disconnectedCallback() {
        if (this._removeEventListeners)
            this._removeEventListeners();
        if (this._removeHostTokenListener)
            this._removeHostTokenListener();
        if (this._removeFeeListener)
            this._removeFeeListener();
        if (this._removeFeeCalcReconnect)
            this._removeFeeCalcReconnect();
    }
    transact(data, element) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c;
            data.fee_mode = (_b = (_a = data.fee_mode) !== null && _a !== void 0 ? _a : this._feeMode) !== null && _b !== void 0 ? _b : defaultFeeMode;
            data.metadata = (_c = data.metadata) !== null && _c !== void 0 ? _c : this._metadata;
            this._isTransactingElement = true;
            const response = yield common.sendTransactingMessage(element, data.payTheoryData.billing_info);
            if (response.type === ERROR_STEP) {
                this._isTransactingElement = false;
                return response;
            }
            const message = {
                type: 'pt-static:payment-detail',
                data: data,
                async: true,
            };
            const transactingIFrame = document.getElementById(this._transactingIFrameId);
            return sendAsyncPostMessage(message, transactingIFrame);
        });
    }
    capture() {
        const message = {
            type: 'pt-static:confirm',
            async: true,
        };
        const transactingIFrame = document.getElementById(this._transactingIFrameId);
        return sendAsyncPostMessage(message, transactingIFrame);
    }
    cancel() {
        return __awaiter(this, void 0, void 0, function* () {
            const transactingIFrame = document.getElementById(this._transactingIFrameId);
            if (transactingIFrame) {
                transactingIFrame.contentWindow.postMessage({
                    type: `pt-static:cancel`,
                }, common.hostedFieldsEndpoint);
                const result = yield this.resetToken();
                if (result.type === ResponseMessageTypes.READY) {
                    this._isTransactingElement = false;
                    this.initialized = false;
                    // Successfully sent the cancel message and reset the token
                    return true;
                }
                else {
                    // Successfully sent the cancel message but failed to reset the token
                    return handleTypedError(ErrorType.CANCEL_FAILED, 'Failed to reset token');
                }
            }
            else {
                // Failed to find the transacting iframe to send the cancel message
                return handleTypedError(ErrorType.CANCEL_FAILED, 'Failed to find transacting iframe');
            }
        });
    }
    tokenize(data, element) {
        return __awaiter(this, void 0, void 0, function* () {
            this._isTransactingElement = true;
            this._initialized = true;
            const response = yield common.sendTransactingMessage(element, data.billingInfo);
            if (response.type === ERROR_STEP) {
                this._isTransactingElement = false;
                return response;
            }
            const message = {
                type: 'pt-static:tokenize-detail',
                data: data,
                async: true,
            };
            const transactingIFrame = document.getElementById(this._transactingIFrameId);
            return sendAsyncPostMessage(message, transactingIFrame);
        });
    }
    sendStateMessage() {
        // Make a copy of the state group
        const newState = Object.assign(Object.assign({}, this._stateGroup), { service_fee: {
                amount: this._amount,
                card_fee: undefined,
                ach_fee: undefined,
                bank_fee: undefined,
            } });
        if (this._fee !== undefined && this._transactingType !== 'cash') {
            newState.service_fee[`${this._transactingType}_fee`] = this._fee;
            // This is for backwards compatibility with the bank fee
            if (this._transactingType === 'bank') {
                newState.service_fee.ach_fee = this._fee;
            }
        }
        // Loop through all the other transacting elements and add their state to the state group or use the default state
        for (const [key, value] of Object.entries(transactingWebComponentMap)) {
            if (key !== this._transactingType) {
                const transactingTypesState = value.ids.reduce((acc, id) => {
                    const element = document.getElementsByName(id);
                    if (element.length > 0) {
                        const transactingElement = element[0];
                        // Check for service fee and add it to the state group
                        if (transactingElement.fee && transactingElement.transactingType !== 'cash') {
                            newState.service_fee[`${transactingElement.transactingType}_fee`] =
                                transactingElement.fee;
                            // This is for backwards compatibility with the bank fee
                            if (transactingElement.transactingType === 'bank') {
                                newState.service_fee.ach_fee = transactingElement.fee;
                            }
                        }
                        return transactingElement.stateGroup;
                    }
                    else {
                        return acc;
                    }
                }, value.defaultState);
                Object.assign(newState, transactingTypesState);
            }
        }
        // Send the state message
        window.postMessage({
            type: 'pay-theory:state',
            data: newState,
        }, window.location.origin);
    }
    sendValidMessage() {
        // If element is valid include it in the valid string
        let valid = this._isValid ? this._transactingType : '';
        // Check to see if all other transacting elements are valid
        for (const [key, value] of Object.entries(transactingWebComponentMap)) {
            if (key !== this._transactingType) {
                value.ids.forEach((id) => {
                    const elements = document.getElementsByName(id);
                    if (elements.length > 0) {
                        const transactingElement = elements[0];
                        if (transactingElement.valid) {
                            // If other transacting elements are valid include them in the valid string
                            valid = valid + ' ' + transactingElement._transactingType;
                        }
                    }
                });
            }
        }
        // Ensure that if we pass a valid string that includes bank that we also pass back ACH for backwards compatibility
        if (valid.includes('bank')) {
            valid = valid + ' ach';
        }
        // Send the updated valid string
        window.postMessage({
            type: 'pay-theory:valid',
            data: valid,
        }, window.location.origin);
    }
    sendReadyMessage() {
        let sendReadyMessage = true;
        // Check to see if all other transacting elements are ready
        for (const [key, value] of Object.entries(transactingWebComponentMap)) {
            if (key !== this._transactingType) {
                value.ids.forEach((id) => {
                    const element = document.getElementsByName(id);
                    if (element.length > 0) {
                        const transactingElement = element[0];
                        if (!(transactingElement === null || transactingElement === void 0 ? void 0 : transactingElement.ready)) {
                            sendReadyMessage = false;
                        }
                    }
                });
            }
        }
        // If all other transacting elements are ready, send the ready message
        if (sendReadyMessage) {
            window.postMessage({
                type: 'pay-theory:ready',
                data: true,
            }, window.location.origin);
            if (this._readyPort) {
                this._readyPort.postMessage({
                    type: 'pay-theory:ready-channel',
                    data: true,
                });
            }
        }
    }
    set apiKey(value) {
        this._apiKey = value;
    }
    set readyPort(value) {
        this._readyPort = value;
    }
    set metadata(value) {
        this._metadata = value;
    }
    get initialized() {
        return this._initialized;
    }
    set initialized(value) {
        this._initialized = value;
    }
    set processedElements(value) {
        this._processedElements = value;
    }
    get ready() {
        return this._isReady;
    }
    get connected() {
        return this._isConnected;
    }
    set connected(value) {
        this._isConnected = value;
    }
    get stateGroup() {
        return this._stateGroup;
    }
    set state(value) {
        // Check to see if the state object has an element property and if it is in the state group
        if ((value === null || value === void 0 ? void 0 : value.element) in this._stateGroup) {
            // Update the state group with the new state
            this._stateGroup[value.element] = value;
            this.sendStateMessage();
            // Check to see if the field has error messages and if so set the error message or clear it if the field is dirty
            const invalid = value.errorMessages.length > 0;
            if (value.isDirty && invalid) {
                this.error = value.errorMessages[0];
            }
            else if (value.isDirty) {
                this.error = '';
            }
            // Check for update to field validity and if there is an update, update the isValid property and send the valid message
            const fieldsToCheck = [...this._processedElements];
            // Make sure we are checking the state for all the fields in the combined card field
            if (fieldsToCheck.includes('credit-card')) {
                fieldsToCheck.push('card-number');
                fieldsToCheck.push('card-exp');
                fieldsToCheck.push('card-cvv');
            }
            const calculatedValid = this._requiredValidFields.reduce((acc, curr) => {
                if (fieldsToCheck.includes(curr)) {
                    return (acc &&
                        this._stateGroup[curr].isDirty &&
                        this._stateGroup[curr].errorMessages.length === 0);
                }
                else {
                    return acc;
                }
            }, true);
            if (this._isValid !== calculatedValid) {
                this._isValid = calculatedValid;
                this.sendValidMessage();
            }
        }
    }
    get error() {
        return this._error;
    }
    set error(value) {
        if (this._error !== value) {
            this._error = value;
            window.postMessage({
                type: 'pt:error',
                error: value,
            }, window.location.origin);
        }
    }
    get fieldTypes() {
        return this._fieldTypes;
    }
    set removeEventListeners(value) {
        this._removeEventListeners = value;
    }
    set feeMode(value) {
        this._feeMode = value;
    }
    get feeMode() {
        return this._feeMode;
    }
    get fee() {
        return this._fee;
    }
    set fee(value) {
        this._fee = value;
        // Send the state message if the fee is updated
        this.sendStateMessage();
    }
    get transactingType() {
        return this._transactingType;
    }
    get valid() {
        return this._isValid;
    }
    get complete() {
        return this._completed;
    }
    set complete(value) {
        this._completed = value;
    }
    set amount(value) {
        this._amount = value;
        if (this._isReady) {
            // If the element is ready, send the amount to the transacting iframe
            postMessageToHostedField(this._transactingIFrameId, {
                type: 'pt-static:update-amount',
                amount: value,
            });
        }
    }
    get amount() {
        return this._amount;
    }
    set session(value) {
        this._session = value;
    }
    set country(value) {
        this._country = value;
        // When the country is set we should also set the required fields for the element
        switch (this._transactingIFrameId) {
            case CARD_IFRAME:
                this._fieldTypes = [...cardFieldTypes.transacting, ...cardFieldTypes.siblings];
                this._requiredValidFields = ['card-number', 'card-cvv', 'card-exp', 'billing-zip'];
                break;
            case BANK_IFRAME:
                if (value === 'CAN') {
                    this._fieldTypes = [...eftFieldTypes.transacting, ...eftFieldTypes.siblings];
                    this._requiredValidFields = [
                        'account-number',
                        'account-name',
                        'account-type',
                        'institution-number',
                        'transit-number',
                    ];
                }
                else {
                    this._fieldTypes = [...achFieldTypes.transacting, ...achFieldTypes.siblings];
                    this._requiredValidFields = [
                        'account-number',
                        'account-name',
                        'account-type',
                        'routing-number',
                    ];
                }
                break;
            case CASH_IFRAME:
                this._fieldTypes = [...cashFieldTypes.transacting, ...cashFieldTypes.siblings];
                this._requiredValidFields = ['cash-name', 'cash-contact'];
                break;
        }
    }
}
export default PayTheoryHostedFieldTransactional;
