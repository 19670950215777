/* eslint-disable no-unused-vars */
/* noinspection JSUnusedGlobalSymbols */
export var ResponseMessageTypes;
(function (ResponseMessageTypes) {
    ResponseMessageTypes["SUCCESS"] = "SUCCESS";
    ResponseMessageTypes["ERROR"] = "ERROR";
    ResponseMessageTypes["CONFIRMATION"] = "CONFIRMATION";
    ResponseMessageTypes["FAILED"] = "FAILED";
    ResponseMessageTypes["CASH"] = "CASH";
    ResponseMessageTypes["TOKENIZED"] = "TOKENIZED";
    ResponseMessageTypes["READY"] = "READY";
})(ResponseMessageTypes || (ResponseMessageTypes = {}));
// Error Types
export var ErrorType;
(function (ErrorType) {
    ErrorType["NO_FIELDS"] = "NO_FIELDS";
    ErrorType["NOT_VALID"] = "NOT_VALID";
    ErrorType["INVALID_PARAM"] = "INVALID_PARAM";
    ErrorType["SESSION_EXPIRED"] = "SESSION_EXPIRED";
    ErrorType["NO_TOKEN"] = "NO_TOKEN";
    ErrorType["FIELD_ERROR"] = "FIELD_ERROR";
    ErrorType["CANCEL_FAILED"] = "CANCEL_FAILED";
    ErrorType["ACTION_COMPLETE"] = "ACTION_COMPLETE";
    ErrorType["ACTION_IN_PROGRESS"] = "ACTION_IN_PROGRESS";
    ErrorType["TRANSACTING_FIELD_ERROR"] = "TRANSACTING_FIELD_ERROR";
    ErrorType["SOCKET_ERROR"] = "SOCKET_ERROR";
    ErrorType["NOT_READY"] = "NOT_READY";
})(ErrorType || (ErrorType = {}));
export var AcceptedPaymentMethods;
(function (AcceptedPaymentMethods) {
    AcceptedPaymentMethods["ALL"] = "ALL";
    AcceptedPaymentMethods["NOT_CASH"] = "NOT_CASH";
    AcceptedPaymentMethods["NOT_CARD"] = "NOT_CARD";
    AcceptedPaymentMethods["NOT_ACH"] = "NOT_ACH";
    AcceptedPaymentMethods["ONLY_CASH"] = "ONLY_CASH";
    AcceptedPaymentMethods["ONLY_CARD"] = "ONLY_CARD";
    AcceptedPaymentMethods["ONLY_ACH"] = "ONLY_ACH";
})(AcceptedPaymentMethods || (AcceptedPaymentMethods = {}));
export var CallToAction;
(function (CallToAction) {
    CallToAction["PAY"] = "PAY";
    CallToAction["DONATE"] = "DONATE";
    CallToAction["BOOK"] = "BOOK";
    CallToAction["CHECKOUT"] = "CHECKOUT";
})(CallToAction || (CallToAction = {}));
export var ButtonColor;
(function (ButtonColor) {
    ButtonColor["PURPLE"] = "PURPLE";
    ButtonColor["WHITE"] = "WHITE";
    ButtonColor["BLACK"] = "BLACK";
    ButtonColor["GREY"] = "GREY";
})(ButtonColor || (ButtonColor = {}));
export var TaxIndicatorType;
(function (TaxIndicatorType) {
    TaxIndicatorType["TAX_AMOUNT_PROVIDED"] = "TAX_AMOUNT_PROVIDED";
    TaxIndicatorType["NOT_TAXABLE"] = "NOT_TAXABLE";
    TaxIndicatorType["NO_TAX_INFO_PROVIDED"] = "NO_TAX_INFO_PROVIDED";
})(TaxIndicatorType || (TaxIndicatorType = {}));
export var HealthExpenseType;
(function (HealthExpenseType) {
    HealthExpenseType["HEALTHCARE"] = "HEALTHCARE";
    HealthExpenseType["RX"] = "RX";
    HealthExpenseType["VISION"] = "VISION";
    HealthExpenseType["CLINICAL"] = "CLINICAL";
    HealthExpenseType["COPAY"] = "COPAY";
    HealthExpenseType["DENTAL"] = "DENTAL";
    HealthExpenseType["TRANSIT"] = "TRANSIT";
})(HealthExpenseType || (HealthExpenseType = {}));
