/* global HTMLElement */
/* eslint-disable no-unused-vars */
import common from '../../common';
//@ts-expect-error - TS doesn't know about DOMPurify
import DOMPurify from 'dompurify';
class PayTheoryHostedField extends HTMLElement {
    constructor() {
        super();
        this._styles = common.defaultStyles;
        this._placeholders = {};
        this.defineFields = this.defineFields.bind(this);
        this.appendElement = this.appendElement.bind(this);
        this.setFields = this.setFields.bind(this);
        this.createToken = this.createToken.bind(this);
        this.fields = [];
    }
    setFields(fieldArray) {
        this.fields = fieldArray;
    }
    setFieldName(name) {
        this._field = name;
    }
    get field() {
        return this._field;
    }
    createToken() {
        const token = {
            origin: window.location.origin,
            styles: this._styles,
            placeholders: this._placeholders,
            amount: this._amount,
            country: this._country,
        };
        const json = JSON.stringify(token);
        const encodedJson = window.btoa(json);
        return encodeURI(encodedJson);
    }
    defineFields(token) {
        this.fields.forEach(field => {
            const wrapper = `field-wrapper-${field === null || field === void 0 ? void 0 : field.replace(/\./, '-')}`;
            const wrapperElement = document.createElement('div');
            wrapperElement.setAttribute('id', wrapper);
            wrapperElement.setAttribute('class', 'field-wrapper');
            const f = document.createElement('iframe');
            f.setAttribute('src', `${common.hostedFieldsEndpoint}/${field}?token=${token}`);
            f.setAttribute('frameBorder', '0');
            f.setAttribute('name', `${field}-iframe`);
            f.setAttribute('id', `${field}-iframe`);
            wrapperElement.appendChild(f);
            this.appendElement(wrapperElement);
        });
    }
    appendElement(element) {
        const container = document.getElementById(`pay-theory-${this.field}-hosted-field-container`);
        container === null || container === void 0 ? void 0 : container.appendChild(element);
    }
    connectedCallback() {
        // eslint-disable-next-line no-unsanitized/property
        this.innerHTML = DOMPurify.sanitize(`<div class="framed">
            <div id="pay-theory-${this.field}-hosted-field-container" class="pay-theory-field">
            </div>
        </div>`);
        const token = this.createToken();
        this.defineFields(token);
    }
    set session(value) {
        this._session = value;
    }
    set styles(value) {
        if (value) {
            this._styles = value;
        }
        else {
            this._styles = { default: {}, success: {}, error: {} };
        }
    }
    set placeholders(value) {
        if (value) {
            this._placeholders = value;
        }
        else {
            this._placeholders = {};
        }
    }
}
export default PayTheoryHostedField;
