var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { findTransactingElement } from '../common/dom';
import common from '../common';
import { transactingWebComponentIds, MERCHANT_FEE } from '../common/data';
import * as valid from './validation';
import { ErrorType, ResponseMessageTypes, } from '../common/pay_theory_types';
import { localizeCashBarcodeUrl, parseResponse } from '../common/format';
import { sendObserverMessage } from '../common/message';
// Used to element to update the token on error or failure or mark as complete on success
const updateElementFromAction = (message, iframe) => {
    if (message.type === ResponseMessageTypes.ERROR || message.type === ResponseMessageTypes.FAILED) {
        iframe.initialized = false;
        iframe.resetToken().catch(e => {
            console.error(e);
        });
    }
    else if (message.type !== ResponseMessageTypes.CONFIRMATION) {
        iframe.complete = true;
    }
};
const reconnectIfDisconnected = (iframe) => __awaiter(void 0, void 0, void 0, function* () {
    if (!iframe.connected) {
        const result = yield iframe.resetToken();
        if (result.type === ResponseMessageTypes.ERROR) {
            return common.handleTypedError(ErrorType.SOCKET_ERROR, result.error);
        }
        return null;
    }
    return null;
});
const transactingFieldCheck = (field) => {
    const isInitialized = field.initialized;
    field.initialized = true;
    // Check to see if the field has already been used to complete an action
    if (field.complete) {
        return common.handleTypedError(ErrorType.ACTION_COMPLETE, 'these fields have already been used to complete an action');
        // Check to see if the field has already been initialized for an action
    }
    else if (isInitialized) {
        return common.handleTypedError(ErrorType.ACTION_IN_PROGRESS, 'this function has already been called');
        // Check to see if the field is valid and ready
    }
    else if (!field.valid) {
        // Reset the initialized flag so the field can be used again
        field.initialized = false;
        return common.handleTypedError(ErrorType.NOT_VALID, 'The transaction element is invalid');
        // Check to see if the field has connected to the socket and is ready
    }
    else if (!field.ready) {
        // Reset the initialized flag so the field can be used again
        field.initialized = false;
        return common.handleTypedError(ErrorType.NOT_READY, 'The transaction element is not ready');
    }
    return null;
};
export const transact = (props) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    const transactingElement = findTransactingElement();
    if (transactingElement) {
        // Run field check and return error if one is found before proceeding
        const checkResult = transactingFieldCheck(transactingElement);
        if (checkResult) {
            return checkResult;
        }
        else {
            const reconnectError = yield reconnectIfDisconnected(transactingElement);
            if (reconnectError)
                return reconnectError;
            // Setting to true so that the transact function can't be called again until the transaction is complete
            transactingElement.initialized = true;
            const newProps = common.parseInputParams(props);
            const { payorInfo, customerInfo, shippingDetails } = newProps;
            newProps.payorInfo = (_b = (_a = payorInfo !== null && payorInfo !== void 0 ? payorInfo : customerInfo) !== null && _a !== void 0 ? _a : shippingDetails) !== null && _b !== void 0 ? _b : {};
            // Adding line for backwards compatability. Default to what was passed into the transact function, then the one passed into create, then the default
            newProps.feeMode = newProps.feeMode
                ? newProps.feeMode
                : transactingElement.feeMode
                    ? transactingElement.feeMode
                    : MERCHANT_FEE;
            // @ts-expect-error Adding line for backwards compatibility
            newProps.feeMode = newProps.feeMode === 'interchange' ? MERCHANT_FEE : newProps.feeMode;
            // Check for validity of the transaction parameters
            const validity = valid.validTransactionParams(newProps);
            if (validity)
                return validity;
            const { amount, payTheoryData, metadata = {}, feeMode, confirmation = false } = newProps;
            const formattedPayor = valid.formatPayorObject((_c = newProps.payorInfo) !== null && _c !== void 0 ? _c : {});
            try {
                const data = {
                    amount,
                    payorInfo: formattedPayor,
                    payTheoryData,
                    metadata,
                    fee_mode: feeMode,
                    confirmation,
                };
                const response = yield transactingElement.transact(data, transactingElement);
                let parsedResponse = parseResponse(response);
                if (parsedResponse.type === ResponseMessageTypes.CASH) {
                    parsedResponse = yield localizeCashBarcodeUrl(parsedResponse);
                }
                updateElementFromAction(parsedResponse, transactingElement);
                sendObserverMessage(parsedResponse);
                return parsedResponse;
            }
            catch (e) {
                if (e instanceof Error) {
                    return common.handleError(e.message);
                }
                else {
                    return common.handleError('An unknown error occurred');
                }
            }
        }
    }
    else {
        return common.handleTypedError(ErrorType.TRANSACTING_FIELD_ERROR, 'No transacting fields found');
    }
});
export const confirm = () => __awaiter(void 0, void 0, void 0, function* () {
    const transactingElement = findTransactingElement();
    if (transactingElement) {
        const reconnectError = yield reconnectIfDisconnected(transactingElement);
        if (reconnectError)
            return reconnectError;
        try {
            const response = yield transactingElement.capture();
            const parsedResult = parseResponse(response);
            updateElementFromAction(parsedResult, transactingElement);
            sendObserverMessage(parsedResult, true);
            return parsedResult;
        }
        catch (e) {
            if (e instanceof Error) {
                return common.handleError(e.message);
            }
            else {
                return common.handleError('An unknown error occurred');
            }
        }
    }
    else {
        return common.handleTypedError(ErrorType.TRANSACTING_FIELD_ERROR, 'No transacting fields found');
    }
});
export const cancel = () => __awaiter(void 0, void 0, void 0, function* () {
    const transactingElement = findTransactingElement();
    if (transactingElement) {
        try {
            return yield transactingElement.cancel();
        }
        catch (e) {
            if (e instanceof Error) {
                return common.handleError(e.message);
            }
            else {
                return common.handleError('An unknown error occurred');
            }
        }
    }
});
export const tokenizePaymentMethod = (props) => __awaiter(void 0, void 0, void 0, function* () {
    const transactingElement = findTransactingElement();
    if (transactingElement) {
        // Run field check and return error if one is found before proceeding
        const checkResult = transactingFieldCheck(transactingElement);
        if (checkResult) {
            return checkResult;
        }
        else {
            const reconnectError = yield reconnectIfDisconnected(transactingElement);
            if (reconnectError)
                return reconnectError;
            transactingElement.initialized = true;
            const { payorInfo = {}, payorId, metadata = {}, billingInfo, skipValidation } = props;
            //validate the input param types
            let error = valid.isValidTokenizeParams(payorInfo, metadata);
            if (error)
                return error;
            //validate the payorInfo
            error = valid.isValidPayorInfo(payorInfo);
            if (error)
                return error;
            // validate the payorId
            error = valid.isValidPayorDetails(payorInfo, payorId);
            if (error)
                return error;
            // validate the billingInfo
            error = valid.isValidBillingInfo(billingInfo);
            if (error)
                return error;
            const formattedPayor = valid.formatPayorObject(payorInfo);
            try {
                const data = {
                    payorInfo: formattedPayor,
                    metadata,
                    payorId,
                    billingInfo,
                    skipValidation,
                };
                const result = yield transactingElement.tokenize(data, transactingElement);
                const parsedResult = parseResponse(result);
                updateElementFromAction(parsedResult, transactingElement);
                // If the tokenization was successful but the validation was skipped, reset the fields
                // This allows the user to modify the fields and tokenize again without getting an error
                if (parsedResult.type === ResponseMessageTypes.TOKENIZED && skipValidation) {
                    for (const id of transactingWebComponentIds) {
                        const elements = document.getElementsByName(id);
                        if (elements.length) {
                            const transactingElement = elements[0];
                            transactingElement.initialized = false;
                            transactingElement.complete = false;
                            transactingElement.connected = false;
                        }
                    }
                }
                sendObserverMessage(parsedResult);
                return parsedResult;
            }
            catch (e) {
                if (e instanceof Error) {
                    return common.handleError(e.message);
                }
                else {
                    return common.handleError('An unknown error occurred');
                }
            }
        }
    }
    else {
        return common.handleTypedError(ErrorType.TRANSACTING_FIELD_ERROR, 'No transacting fields found');
    }
});
export const activateCardPresentDevice = () => {
    return true;
};
export const updateAmount = (amount) => __awaiter(void 0, void 0, void 0, function* () {
    const error = valid.isValidAmount(amount);
    if (error) {
        return error;
    }
    const foundFields = [];
    // Loop through the hosted fields and check if they are ready and connected. If so add to the foundFields array
    for (const id of transactingWebComponentIds) {
        const elements = document.getElementsByName(id);
        if (elements.length) {
            const transactingElement = elements[0];
            if (!transactingElement.ready) {
                return common.handleTypedError(ErrorType.NOT_READY, 'Not all fields are ready to update the amount');
            }
            const reconnectError = yield reconnectIfDisconnected(transactingElement);
            if (reconnectError)
                return reconnectError;
            foundFields.push(transactingElement);
        }
    }
    // Return an error if there are no fields to update the amount for
    if (foundFields.length === 0) {
        return common.handleTypedError(ErrorType.NO_FIELDS, 'There are no PayTheory fields to update the amount for');
    }
    // Loop through the foundFields and update the amount for each
    for (const transactingElement of foundFields) {
        if (transactingElement.amount !== amount) {
            // Set the amount to the new amount and reset the fee so it can be recalculated
            transactingElement.amount = amount;
            transactingElement.fee = undefined;
        }
    }
    return true;
});
