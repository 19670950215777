/* eslint-disable security/detect-object-injection */
import { handleTypedError } from './message';
import { transactingWebComponentIds, webComponentMap, } from './data';
import { ErrorType } from './pay_theory_types';
export const findTransactingElement = () => {
    let result = false;
    transactingWebComponentIds.forEach(id => {
        const element = document.getElementsByName(id);
        if (element.length > 0) {
            const transactingElement = element[0];
            if (!isHidden(transactingElement)) {
                if (result === false) {
                    result = transactingElement;
                }
                else {
                    handleTypedError(ErrorType.TRANSACTING_FIELD_ERROR, 'There can only be one transacting element visible on the page.');
                    return false;
                }
            }
        }
    });
    return result;
};
export const addFrame = (frameType, element) => {
    const tagFrame = document.createElement(frameType);
    tagFrame.setAttribute('id', `${element}-tag-frame`);
    tagFrame.setAttribute('name', frameType);
    return tagFrame;
};
const processContainer = (elements, type) => {
    const contained = document.getElementById(`${elements[type]}-tag-frame`);
    if (contained === null) {
        const frameType = webComponentMap[type];
        const frame = addFrame(frameType, elements[type]);
        return { type, frame, containerId: elements[type] };
    }
    else {
        return `${elements[type]} is already mounted`;
    }
};
const findElementError = (elements, type) => {
    const element = elements[type];
    return typeof element === 'undefined' ? `unknown type ${type}` : false;
};
export const processElements = (elements, fieldTypes) => {
    const processed = {
        transacting: [],
        siblings: [],
    };
    let key;
    for (key in fieldTypes) {
        fieldTypes[key].forEach(type => {
            let error = findElementError(elements, type);
            const container = document.getElementById(elements[type]);
            if (container && error === false) {
                const result = processContainer(elements, type);
                if (typeof result === 'string') {
                    error = result;
                }
                else {
                    //@ts-expect-error - result is not a string
                    processed[key].push(result);
                }
            }
            if (error) {
                return handleTypedError(ErrorType.FIELD_ERROR, error);
            }
        });
    }
    return processed;
};
export const isHidden = (element) => {
    if (!element)
        return true;
    const style = window.getComputedStyle(element);
    if (style.display === 'none') {
        return true;
    }
    else if (element.parentElement) {
        return isHidden(element.parentElement);
    }
    else {
        return false;
    }
};
