/* eslint-disable no-unused-vars */
import * as messaging from './message';
import { transact, cancel, confirm, tokenizePaymentMethod, activateCardPresentDevice, } from '../field-set/actions';
export const errorObserver = (cb) => messaging.handleMessage(messaging.errorTypeMessage, (message) => {
    cb(message.error);
});
export const stateObserver = (cb) => messaging.handleMessage(messaging.stateTypeMessage, (event) => {
    cb(event.data);
});
export const validObserver = (cb) => messaging.handleMessage(messaging.validTypeMessage, (event) => {
    cb(event.data);
});
export const readyObserver = (cb) => messaging.handleMessage(messaging.readyTypeMessage, () => {
    cb(true);
});
export const tokenizeObserver = (cb) => messaging.handleMessage(messaging.confirmTypeMessage, (message) => {
    cb(message.body);
});
export const captureObserver = (cb) => messaging.handleMessage(messaging.confirmationCompleteTypeMessage, (message) => {
    cb(message.body);
});
export const transactedObserver = (cb) => messaging.handleMessage(messaging.completeTypeMessage, (message) => {
    cb(message.body);
});
export const cashObserver = (cb) => messaging.handleMessage(messaging.cashTypeMessage, (message) => {
    cb(message.body);
});
// export const cardPresentObserver = (cb: (value: any) => void) =>
//   messaging.handleHostedFieldMessage(messaging.cardPresentTypeMessage, (message: any) => {
//     cb(message.body);
//   });
export const generateReturn = (mount, initTransaction) => {
    return {
        mount,
        initTransaction,
        transact,
        tokenizePaymentMethod,
        activateCardPresentDevice,
        confirm,
        cancel,
        readyObserver,
        errorObserver,
        validObserver,
        cashObserver,
        captureObserver,
        tokenizeObserver,
        transactedObserver,
        stateObserver,
    };
};
