var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { postMessageToHostedField } from './message';
import { BANK_IFRAME, CARD_IFRAME, CASH_IFRAME } from './data';
export const getData = (url, apiKey, sessionKey) => __awaiter(void 0, void 0, void 0, function* () {
    const options = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'x-api-key': apiKey,
            'x-session-key': sessionKey,
        },
    };
    /* global fetch */
    const response = yield fetch(url, options);
    return yield response.json();
});
export const PARTNER = process.env.ENV;
export const STAGE = process.env.STAGE;
const TARGET_MODE = process.env.TARGET_MODE;
const ENVIRONMENT = `${PARTNER}${TARGET_MODE}`;
export const transactionEndpoint = `https://${ENVIRONMENT}.${STAGE}.com/pt-token-service/`;
export const hostedFieldsEndpoint = `https://${ENVIRONMENT}.tags.static.${STAGE}.com`;
export const hostedCheckoutEndpoint = `https://${ENVIRONMENT}.checkout.${STAGE}.com`;
export const fetchPtToken = (apiKey, sessionKey) => __awaiter(void 0, void 0, void 0, function* () {
    for (let i = 0; i < 5; i++) {
        const token = yield getData(transactionEndpoint, apiKey, sessionKey);
        if (token['pt-token']) {
            return token;
        }
    }
    return false;
});
const sendTransactingMessageToField = (field, billingInfo, channel) => {
    postMessageToHostedField(`${field}-iframe`, {
        type: 'pt-static:transact',
        element: field,
        billingInfo,
    }, channel);
};
export const sendTransactingMessage = (transacting, billingInfo) => new Promise(resolve => {
    // Opening a new message channel, so we can await the response from the hosted field
    const channel = new MessageChannel();
    channel.port1.onmessage = ({ data }) => {
        channel.port1.close();
        resolve(data);
    };
    const types = transacting.fieldTypes;
    const transactingField = types.filter(field => [CASH_IFRAME, BANK_IFRAME, CARD_IFRAME].includes(`${field}-iframe`));
    const siblingFields = types.filter(field => ![CASH_IFRAME, BANK_IFRAME, CARD_IFRAME].includes(`${field}-iframe`));
    // Sending the message to the hosted field to transact first so that the channel port is saved in state
    // That will allow it to respond once it receives the message from the sibling fields
    transactingField.forEach(field => {
        sendTransactingMessageToField(field, billingInfo, channel.port2);
    });
    siblingFields.forEach(field => {
        sendTransactingMessageToField(field, billingInfo);
    });
});
