import { hostedCheckoutEndpoint, hostedFieldsEndpoint } from './network';
import { ResponseMessageTypes, } from './pay_theory_types';
const windowListenerHandler = (validTarget, handleMessage, event) => {
    // If payTheory is not set to true on the event then ignore as it is not from one of our listeners
    if (!event.payTheory)
        return;
    let message = event.data;
    if (typeof message !== 'object') {
        try {
            // Parse the message as JSON.
            // All PT messages have either an object or a stringified object as the data
            message = JSON.parse(message);
        }
        catch (e) {
            // Do nothing
            return;
        }
    }
    if (validTarget(message)) {
        const response = handleMessage(message);
        if (response instanceof Promise) {
            response.catch((error) => handleError(error));
        }
    }
};
const generateWindowListener = (validTarget, handleMessage) => {
    return (event) => {
        if ([window.location.origin].includes(event.origin)) {
            const newEvent = event;
            newEvent.payTheory = true;
            windowListenerHandler(validTarget, handleMessage, newEvent);
        }
    };
};
const generateiFrameWindowListener = (validTarget, handleMessage) => {
    return (event) => {
        if (event.origin === hostedFieldsEndpoint) {
            const newEvent = event;
            newEvent.payTheory = true;
            windowListenerHandler(validTarget, handleMessage, newEvent);
        }
    };
};
const generateCheckoutWindowListener = (validTarget, handleMessage) => {
    return (event) => {
        if (event.origin === hostedCheckoutEndpoint) {
            const newEvent = event;
            newEvent.payTheory = true;
            windowListenerHandler(validTarget, handleMessage, newEvent);
        }
    };
};
export const handleMessage = (validTarget, handleMessage) => {
    const func = generateWindowListener(validTarget, handleMessage);
    window.addEventListener('message', func);
    return () => {
        window.removeEventListener('message', func);
    };
};
export const handleHostedFieldMessage = (validTarget, handleMessage) => {
    const func = generateiFrameWindowListener(validTarget, handleMessage);
    window.addEventListener('message', func);
    return () => {
        window.removeEventListener('message', func);
    };
};
export const handleCheckoutMessage = (validTarget, handleMessage) => {
    const func = generateCheckoutWindowListener(validTarget, handleMessage);
    window.addEventListener('message', func);
    return () => {
        window.removeEventListener('message', func);
    };
};
export const sendAsyncPostMessage = (message, iframe) => new Promise(resolve => {
    // Opening a new message channel, so we can await the response from the hosted field
    const channel = new MessageChannel();
    channel.port1.onmessage = ({ data }) => {
        channel.port1.close();
        resolve(data);
    };
    iframe.contentWindow.postMessage(message, hostedFieldsEndpoint, [channel.port2]);
});
export const errorTypeMessage = (message) => typeof message.type === 'string' && message.type === 'pt:error';
export const readyTypeMessage = (message) => typeof message.type === 'string' && message.type === 'pay-theory:ready';
export const stateTypeMessage = (message) => typeof message.type === 'string' && message.type === 'pay-theory:state';
export const validTypeMessage = (message) => typeof message.type === 'string' && message.type === 'pay-theory:valid';
export const hostedStateTypeMessage = (message) => typeof message.type === 'string' && message.type === 'pt-static:state';
export const relayTypeMessage = (message) => typeof message.type === 'string' && message.type === 'pt-static:relay';
//Sends message to the tokenize observer
export const confirmString = 'pt:confirm';
export const confirmTypeMessage = (message) => typeof message.type === 'string' && message.type === confirmString;
//Sends message to the transacted observer
export const completeString = 'pt:complete';
export const completeTypeMessage = (message) => typeof message.type === 'string' && message.type === completeString;
//Sends message to the capture observer
export const captureString = 'pt:capture';
export const confirmationCompleteTypeMessage = (message) => typeof message.type === 'string' && message.type === captureString;
//Sends message to the cash observer
export const cashString = 'pt:cash';
export const cashTypeMessage = (message) => typeof message.type === 'string' && message.type === cashString;
//passes socket error from the hosted fields to SDK
export const socketErrorTypeMessage = (message) => typeof message.type === 'string' && message.type === 'pt-static:error';
//Message sent from hosted-fields with data when a card present device is activated or response is received from processor
export const cardPresentTypeMessage = (message) => typeof message.type === 'string' && message.type === 'pt-static:card-present';
// Message sent from hosted-fields when a hosted button is clicked
export const buttonClickTypeMessage = (message) => typeof message.type === 'string' && message.type === 'pt-static:button-click';
// Message sent from hosted-fields when a hosted button is clicked
export const buttonReadyTypeMessage = (message) => typeof message.type === 'string' && message.type === 'pt-static:button-ready';
// Message sent from the checkout page when there is an error
export const checkoutErrorTypeMessage = (message) => typeof message.type === 'string' && message.type === 'pt-checkout:error';
// Message sent from the checkout page when the payment is complete
export const checkoutCompleteTypeMessage = (message) => typeof message.type === 'string' && message.type === 'pt-checkout:complete';
// Message sent from the checkout page when the barcode is received
export const checkoutBarcodeReceivedTypeMessage = (message) => typeof message.type === 'string' && message.type === 'pt-checkout:barcode-received';
// Message from the overlay when the user clicks the close button
export const overlayCancelTypeMessage = (message) => typeof message.type === 'string' && message.type === 'pt-overlay:cancel';
// Message from the overlay when the user clicks the relaunch button
export const overlayRelaunchTypeMessage = (message) => typeof message.type === 'string' && message.type === 'pt-overlay:relaunch';
// Message from the qr code when it is ready
export const qrCodeReadyTypeMessage = (message) => typeof message.type === 'string' && message.type === 'pt-static:qr-ready';
// Message from the qr code when it is completes a successful transaction
export const qrCodeCompleteTypeMessage = (message) => typeof message.type === 'string' && message.type === 'pt-static:qr-checkout-success';
export const postMessageToHostedField = (id, message, channel) => {
    const elements = document.getElementsByName(id);
    if (elements.length) {
        const element = elements[0];
        if (channel) {
            element.contentWindow.postMessage(message, hostedFieldsEndpoint, [channel]);
        }
        else {
            element.contentWindow.postMessage(message, hostedFieldsEndpoint);
        }
    }
    else {
        console.log('Hosted Field not found');
    }
};
export const handleError = (error) => {
    window.postMessage({
        type: 'pt:error',
        error,
    }, window.location.origin);
    return {
        type: ResponseMessageTypes.ERROR,
        error,
    };
};
export const handleTypedError = (type, error) => {
    const errorString = `${type}: ${error}`;
    return handleError(errorString);
};
// This function is used for backwards compatibility with the observer functions that were used before the SDK was set up with async functions using MessageChannel
export const sendObserverMessage = (message, confirmationResponse = false) => {
    switch (message.type) {
        case ResponseMessageTypes.SUCCESS:
        case ResponseMessageTypes.TOKENIZED:
        case ResponseMessageTypes.FAILED: {
            const messageType = confirmationResponse ? captureString : completeString;
            window.postMessage({
                type: messageType,
                body: message.body,
            }, window.location.origin);
            break;
        }
        case ResponseMessageTypes.CASH: {
            window.postMessage({
                type: cashString,
                body: message.body,
            }, window.location.origin);
            break;
        }
        case ResponseMessageTypes.CONFIRMATION: {
            window.postMessage({
                type: confirmString,
                body: message.body,
            }, window.location.origin);
            break;
        }
        case ResponseMessageTypes.ERROR: {
            // Do nothing. Error message should have already been sent
            break;
        }
        default:
            // Do nothing
            break;
    }
};
