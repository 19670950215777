import common from '../common';
import { transactingWebComponentIds, transactingWebComponentMap, } from '../common/data';
//relays state to the hosted fields to tokenize the instrument
const verifyRelay = (fields, message) => {
    fields.forEach(field => {
        common.postMessageToHostedField(field, message);
    });
};
const autofillHandler = (message) => {
    if (message.element === 'card-autofill') {
        const cardFields = ['card-name-iframe', 'card-cvv-iframe', 'card-exp-iframe'];
        verifyRelay(cardFields, message);
    }
    else if (message.element === 'address-autofill') {
        const addressFields = [
            'billing-line2-iframe',
            'billing-city-iframe',
            'billing-state-iframe',
            'billing-zip-iframe',
        ];
        verifyRelay(addressFields, message);
    }
};
//Relay messages from hosted fields to the transacting element for autofill and transacting
export const relayHandler = (message) => {
    if (message.element === 'card-autofill' || message.element === 'address-autofill') {
        autofillHandler(message);
    }
    else {
        const fieldType = common.isFieldType(message.element);
        if (fieldType)
            common.postMessageToHostedField(common.hostedFieldMap[fieldType], message);
    }
};
//Handles state messages and sets state on the web components
export const stateUpdater = (message) => {
    transactingWebComponentIds.forEach(id => {
        const element = document.getElementsByName(id);
        if (element.length > 0) {
            const transactingElement = element[0];
            const state = message.state;
            transactingElement.state = Object.assign(Object.assign({}, state), { element: message.element });
        }
    });
};
export const hostedErrorHandler = (message) => {
    const fieldType = common.isFieldType(message.field);
    if (fieldType) {
        const components = transactingWebComponentMap[fieldType];
        components.ids.forEach(id => {
            const element = document.getElementsByName(id);
            if (element.length > 0) {
                const transactingElement = element[0];
                if (transactingElement.initialized) {
                    transactingElement.initialized = false;
                    transactingElement.resetToken().catch(() => {
                        common.handleError('Error resetting token');
                    });
                }
                // If the session has expired, set the ready state to false
                if (message.error.startsWith('SESSION_EXPIRED'))
                    transactingElement.connected = false;
            }
        });
    }
    // Do not throw an error if the error is a session expired error
    if (!message.error.startsWith('SESSION_EXPIRED'))
        common.handleError(message.error);
};
