/* global HTMLElement */
/* eslint-disable no-unused-vars */
import common from '../../common';
class PayTheoryCheckoutButton extends HTMLElement {
    defineButton() {
        // Creating the iFrame for the Button
        const buttonFrame = document.createElement('iframe');
        buttonFrame.setAttribute('src', `${common.hostedFieldsEndpoint}/checkout_button?token=${this._token}`);
        buttonFrame.setAttribute('frameBorder', '0');
        buttonFrame.setAttribute('name', common.checkoutButtonField);
        buttonFrame.setAttribute('id', `${common.checkoutButtonField}-iframe`);
        this.append(buttonFrame);
    }
    connectedCallback() {
        // Creating the listeners from the hosted checkout page
        this._clearErrorListener = common.handleCheckoutMessage(common.checkoutErrorTypeMessage, this._onError);
        this._clearSuccessListener = common.handleCheckoutMessage(common.checkoutCompleteTypeMessage, this._onSuccess);
        this._clearBarcodeReceivedListener = common.handleCheckoutMessage(common.checkoutBarcodeReceivedTypeMessage, (message) => {
            this._buttonBarcode = JSON.stringify(message.data);
        });
        // Creating the listeners from the hosted button page
        this._clearReadyListener = common.handleHostedFieldMessage(common.buttonReadyTypeMessage, this._onReady);
        this._clearClickListener = common.handleHostedFieldMessage(common.buttonClickTypeMessage, this._onClick);
    }
    disconnectedCallback() {
        if (this._clearErrorListener)
            this._clearErrorListener();
        if (this._clearSuccessListener)
            this._clearSuccessListener();
        if (this._clearReadyListener)
            this._clearReadyListener();
        if (this._clearClickListener)
            this._clearClickListener();
        if (this._clearBarcodeReceivedListener)
            this._clearBarcodeReceivedListener();
        if (this._closeInterval)
            clearInterval(this._closeInterval);
    }
    // Only want to allow event listeners to be set from outside the class
    // If they have been set before there should be a clear listener function so we want to clear it and reset the listener
    set onReady(readyFunc) {
        this._onReady = readyFunc;
    }
    set onClick(clickFunc) {
        this._onClick = clickFunc;
    }
    set onError(errorFunc) {
        this._onError = errorFunc;
    }
    set onSuccess(successFunc) {
        this._onSuccess = successFunc;
    }
    set token(value) {
        this._token = value;
        this.defineButton();
    }
    set checkoutWindow(window) {
        this._checkoutWindow = window;
    }
    get checkoutWindow() {
        return this._checkoutWindow;
    }
    set closeInterval(interval) {
        this._closeInterval = interval;
    }
    get closeInterval() {
        return this._closeInterval;
    }
    get session() {
        return this._session;
    }
    set session(value) {
        this._session = value;
    }
    get buttonBarcode() {
        return this._buttonBarcode;
    }
    set buttonBarcode(value) {
        this._buttonBarcode = value;
    }
}
window.customElements.define(common.checkoutButtonField, PayTheoryCheckoutButton);
export default PayTheoryCheckoutButton;
