var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import common from '../common';
import { defaultElementIds } from '../common/data';
import payTheoryFields from './payment-fields-v2';
import { transact } from './actions';
export default (apiKey_1, legacy_1, ...args_1) => __awaiter(void 0, [apiKey_1, legacy_1, ...args_1], void 0, function* (apiKey, legacy, // this used to be client id, left in place to preserve backwards compatibility
styles = common.defaultStyles, sessionMetadata = {}, fee_mode = common.defaultFeeMode) {
    const mount = (...args_2) => __awaiter(void 0, [...args_2], void 0, function* (props = {}) {
        const { placeholders, elements = defaultElementIds, session } = props;
        yield payTheoryFields({
            apiKey: apiKey,
            styles: styles,
            metadata: sessionMetadata,
            placeholders: placeholders,
            elementIds: elements,
            session: session,
            feeMode: fee_mode,
        });
    });
    const initTransaction = (amount, payorInfo, confirmation = false) => {
        console.warn('initTransaction is deprecated. Please use transact instead.');
        //Passing in the session metadata from create because those used to be the only metadata that were passed in
        transact({ amount, payorInfo, confirmation, feeMode: fee_mode }).catch(error => {
            console.error(error);
        });
    };
    return common.generateReturn(mount, initTransaction);
});
